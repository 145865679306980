import "realgrid/dist/realgrid-style.css";
import "@toast-ui/chart/toastui-chart.css";
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import "tui-calendar/dist/tui-calendar.css";
import "swiper/css/bundle";
import "./src/assets/ustra-ehr-scss/src/scss/ustra-desktop.scss";
import "simplebar/dist/simplebar.css";

import "lodash";
import SimpleBar from 'simpleBar';
import Swiper from "swiper/bundle";
window.Swiper = Swiper;
window.SimpleBar = SimpleBar;
window.jQuery = window.$ = require("jquery");
// prettier-ignore
$.extend(this,
    require("jquery-ui/ui/widgets/draggable"),
    require("jquery-ui/ui/widgets/tooltip"),
    require("jquery-ui/ui/widgets/dialog"),
    // require("jquery-ui"),
    require("bootstrap-datepicker"),
    require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.ko.min.js"),
);
window.RealGrid = require("realgrid");
window.realGrid2Lic = 'upVcPE+wPOmtLjqyBIh9RkM/nBOseBrflwxYpzGZyYm9cY8amGDkiMnVeQKUHJDjW2y71jtk+wteqHQ1mRMIXzEcGIrzZpzzNTakk0yR9UcO/hzNodVsIiqQNVtxmmYt';
window.Chart = require("@toast-ui/chart");
window.DatePicker = require("tui-date-picker");
window.TimePicker = require("tui-time-picker");
window.moment = require("moment");
window.Chance = require('chance');
window.Calendar = require("tui-calendar");
window.Calendar2 = require("@toast-ui/calendar");
window.CustomEvents = require('tui-code-snippet/customEvents/customEvents');
window.throttled = require("tui-code-snippet/tricks/throttle");

const cache = {};
function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}
importAll(require.context("./src/assets/images/svg", true, /\.svg$/));